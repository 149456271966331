import type {
  IGetAdsRequest,
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
  ISearchPageFilter,
  ISearchPageFilterValue,
  SeoFaqSchema,
  TypePrice,
} from 'api/types/searchPageApiTypes';

import {
  type IQueryParams,
  type UrlParamTypeOptional,
  type ResponseMakeModelType,
  CURRENCIES,
} from 'types';

const DEFAULT_PAGE_SIZE = 30;

const getFilterValues = (filters: ISearchPageFilter[], name: string) =>
  filters.find((filter) => filter.name === name)?.values;

const getSubFilterValues = (filters: ISearchPageFilter[], name: string) => {
  const filter = filters.find((filter) => filter.name === name)?.subFilters;
  if (filter && filter[0]) {
    return filter[0].values;
  }
};

const buildFilterTerm = (name: string, term: string) => ({
  [name]: term,
});

const mapSterling = (str: string) => str.replace('sterling', '').toLowerCase();

const mapPerMonth = (str: string) => str.replace('PerMonth', '').toLowerCase();

const findValue = (values: ISearchPageFilterValue[], term: string) =>
  values?.find((value) => value.displayName === term)?.displayName;

const findLocationValue = (values: ISearchPageFilterValue[], term: string) =>
  values?.find((value) => value.value === term)?.value;

const verifyYear = (value: string) => {
  return /\d{4}$/.test(value);
};

const getQueryParams = (filters: ISearchPageFilter[], filterTerm: string) => {
  const fuelTypeValues = getFilterValues(filters, 'fuelType');
  const bodyTypeValues = getFilterValues(filters, 'bodyType');
  const transmissionValues = getFilterValues(filters, 'transmission');
  const colourValues = getFilterValues(filters, 'colour');
  const areaValues = getFilterValues(filters, 'area');
  const locationValues = getSubFilterValues(filters, 'location');

  // @ts-ignore
  const yearValues:
    | {
        valueType: string;
        from: ISearchPageFilterValue[];
        to: ISearchPageFilterValue[];
      }[]
    | undefined = getFilterValues(filters, 'year');

  const isFuelType =
    fuelTypeValues &&
    fuelTypeValues.length > 0 &&
    findValue(fuelTypeValues, filterTerm);
  const isBodyType =
    bodyTypeValues &&
    bodyTypeValues.length > 0 &&
    findValue(bodyTypeValues, filterTerm);
  const isTransmission =
    transmissionValues &&
    transmissionValues.length > 0 &&
    findValue(transmissionValues, filterTerm);
  const isColour =
    colourValues &&
    colourValues.length > 0 &&
    findValue(colourValues, filterTerm);
  const isArea =
    areaValues && areaValues.length > 0 && findValue(areaValues, filterTerm);
  const isLocation =
    locationValues &&
    locationValues.length > 0 &&
    findLocationValue(locationValues, filterTerm);
  const isYearValue =
    yearValues && yearValues.length > 0
      ? findValue(yearValues[0].from, filterTerm)
      : false;
  const isNativeYearValue = !isYearValue && verifyYear(filterTerm);

  if (isFuelType) return buildFilterTerm('fuelType', filterTerm);
  if (isBodyType) return buildFilterTerm('bodyType', filterTerm);
  if (isTransmission) return buildFilterTerm('transmission', filterTerm);
  if (isColour) return buildFilterTerm('colour', filterTerm);
  else if (isArea) return buildFilterTerm('area', filterTerm);
  else if (isLocation) return buildFilterTerm('area', filterTerm);
  else if (isYearValue || isNativeYearValue)
    return {
      ...buildFilterTerm('year_from', filterTerm),
      ...buildFilterTerm('year_to', filterTerm),
    };
  else return null;
};

const getMakeModelParams = (make: string | string[]): ResponseMakeModelType => {
  let makeModel: ResponseMakeModelType = [];
  const newMake = Array.isArray(make) ? make : [make];
  newMake.forEach((makeItem) => {
    const hasModel = makeItem.includes('model');
    if (hasModel) {
      const [make, model] = makeItem.replace('model:', '').split(';');
      const models = model.split(',') || [model];
      return (
        models.length > 0 &&
        models.forEach((modelItem) =>
          makeModel.push({
            make,
            model: modelItem,
            trim: '',
          }),
        )
      );
    } else {
      makeModel.push({
        make: makeItem,
        model: '',
        trim: '',
      });
    }
  });
  return makeModel;
};

const getFriendlyParams = (section: UrlParamTypeOptional) => {
  return {
    friendlyParamMake: (section && section[1]) || '',
    friendlyParamModel: (section && section[2]) || '',
    friendlyParamFilter: (section && section[3]) || '',
  };
};

const getSectionParams = (
  filters: ISearchPageFilter[],
  queryParams?: IQueryParams,
) => {
  const makeQueryParams = queryParams?.make;

  const sectionQueryParam = queryParams?.section;
  const makeModelFilter = filters.find((filter) => filter.name === 'makeModel');
  const isMakeModel = !!makeModelFilter;
  const locationSubfilters = filters.find(
    (filter) => filter.name === 'location',
  )?.subFilters;
  const countyList =
    locationSubfilters && locationSubfilters[0].values
      ? locationSubfilters[0].values?.map((county) => {
          return {
            displayName: county.displayName || '',
            value: county.value || '',
          };
        })
      : [];
  const radiusList =
    locationSubfilters && locationSubfilters[2].values
      ? locationSubfilters[2].values?.map((county) => {
          return {
            label: county.displayName || '',
            value: county.value || '',
          };
        })
      : [];

  const { friendlyParamMake, friendlyParamModel, friendlyParamFilter } =
    getFriendlyParams(sectionQueryParam);

  const makeModelRequestParams =
    isMakeModel && makeQueryParams && getMakeModelParams(makeQueryParams);

  const makeModelFriendlyParams = isMakeModel &&
    friendlyParamMake && [
      {
        make: friendlyParamMake,
        model: friendlyParamModel,
        trim: '',
      },
    ];

  const sectionQueryParams: {
    [filter: string]: string | string[];
  } | null = {
    ...getQueryParams(filters, friendlyParamFilter),
    ...(!isMakeModel && {
      make: makeQueryParams ? makeQueryParams : friendlyParamMake,
    }),
  };

  const makeModelParams =
    makeModelRequestParams || makeModelFriendlyParams || [];

  return {
    isMakeModel,
    makeModelFilter,
    makeModelParams,
    sectionQueryParams,
    countyList,
    radiusList,
  };
};

// Return "true" if no value to accommodate query params without values
const mapParamToFilterValues = (
  value: string | string[] | undefined,
): string[] => (Array.isArray(value) ? value : [value || '']);

const mapParamToRangeValue = (value: string | string[] | undefined) =>
  Array.isArray(value) ? value.join(' ') : value || '';

export function mapFilterAndRangeValues(
  queryParams: IQueryParams,
  filters: ISearchPageFilter[],
  userId: string | string[] | undefined,
) {
  const filterGroup: IGetAdsRequestFilter[] = [];
  const rangeGroup: IGetAdsRequestRange[] = [];

  if (userId) {
    Array.isArray(userId)
      ? filterGroup.push({
          name: 'userId',
          values: userId,
        })
      : filterGroup.push({
          name: 'userId',
          values: [userId],
        });
  }

  const includesAreaFilter =
    filters.filter((item) => item.name === 'area').length > 0;

  if (queryParams.area && !includesAreaFilter && !queryParams.countyTown) {
    Array.isArray(queryParams.area)
      ? filterGroup.push({
          name: 'area',
          values: queryParams.area,
        })
      : filterGroup.push({
          name: 'area',
          values: [queryParams.area],
        });
  }

  for (const key of Object.keys(queryParams)) {
    const isSterling = key.startsWith('sterlingPrice');
    const isPerMonth = key.startsWith('pricePerMonth');
    const isTo = key.endsWith('_to');
    const isFrom = key.endsWith('_from');
    let name = isSterling ? mapSterling(key) : key;
    let value = queryParams[key];

    if (isPerMonth) name = mapPerMonth(name);

    // Check if you need to remove _to or _from from name
    if (isTo) {
      name = name.substring(0, name.length - 3);
    } else if (isFrom) {
      name = name.substring(0, name.length - 5);
    }

    const filter = filters.find((item) => name === item.name);

    if (!filter) {
      continue;
    }

    if (filter.searchQueryGroup === 'filters') {
      filterGroup.push({
        name: filter.name,
        values:
          filter.name === 'cashOffer'
            ? ['true']
            : mapParamToFilterValues(value),
      });
    } else if (filter.searchQueryGroup === 'ranges') {
      // Check if we already have an entry for this range
      const rangeIndex = rangeGroup.findIndex(
        (item) => filter.name === item.name,
      );
      const formattedValue = mapParamToRangeValue(value);

      if (rangeIndex >= 0) {
        // Range exists so update appropriate field
        if (isFrom) {
          rangeGroup[rangeIndex].from = formattedValue;
        } else {
          rangeGroup[rangeIndex].to = formattedValue;
        }
      } else {
        const priceFields: {
          currency?: CURRENCIES;
          typePrice?: TypePrice;
        } =
          filter.name === 'price' || filter.name === 'pricePerMonth'
            ? {
                currency: isSterling ? CURRENCIES.GBP : CURRENCIES.EUR,
                typePrice: isPerMonth ? 'pricePerMonth' : 'price',
              }
            : {};

        rangeGroup.push({
          name: filter.name,
          from: isFrom ? formattedValue.replace(' ', '+') : '',
          to: isTo ? formattedValue.replace(' ', '+') : '',
          ...priceFields,
        });
      }
    }
  }

  if (queryParams.containsValidPrice === 'true') {
    filterGroup.push({
      name: 'containsValidPrice',
      values: ['true'],
    });
  }

  return {
    filterGroup,
    rangeGroup,
  };
}

const generateGetAdsRequestParams = (
  section: string,
  queryParams: IQueryParams,
  filters: ISearchPageFilter[],
  userId: string | string[] | undefined,
  pageSize?: number,
): IGetAdsRequest => {
  delete queryParams.section;

  // pull out pagination
  const start =
    (Array.isArray(queryParams.start)
      ? queryParams.start[queryParams.start.length - 1]
      : queryParams.start) || '';
  delete queryParams.start;

  // pull out search terms
  const terms = Array.isArray(queryParams.words)
    ? queryParams.words.join(' ')
    : queryParams.words;
  delete queryParams.words;

  // pull out sort
  const sort = (
    Array.isArray(queryParams.sort)
      ? queryParams.sort.join(' ')
      : queryParams.sort
  )?.replace(' ', '');
  delete queryParams.sort;

  const { filterGroup, rangeGroup } = mapFilterAndRangeValues(
    queryParams,
    filters,
    userId,
  );

  return {
    filters: filterGroup,
    ranges: rangeGroup,
    paging: {
      pageSize: pageSize || DEFAULT_PAGE_SIZE,
      from: parseInt(start) || 0,
    },
    sections: [section],
    ...(terms && { terms }),
    sort,
  };
};

/** Compare filter values contained in filter to SEO filter path parameter (if any)
 * and return name of filter and value
 * @param filter filter with values to be compared to path parameter
 * @param SEOFilterQuery path parameter after make and model */
const getFilterPathParameterData = (
  filters?: Array<ISearchPageFilter>,
  SEOFilterQuery?: string,
) => {
  if (!SEOFilterQuery || !filters) return {};
  const filterPathParameter = getQueryParams(filters, SEOFilterQuery);
  const [filterName] = filterPathParameter
    ? Object.keys(filterPathParameter)
    : [];
  const filterValue =
    filterPathParameter && filterName ? filterPathParameter[filterName] : '';
  const [formattedFilterName] = filterName ? filterName.split('_') : [];
  const filterSearchQueryGroup = filters.find(
    ({ name }) => name === formattedFilterName,
  )?.searchQueryGroup;

  return {
    filterPathParameter,
    formattedFilterName,
    filterValue,
    filterSearchQueryGroup,
  };
};

const formatFaqSchema = (faqSchema: SeoFaqSchema, section?: Array<string>) => {
  const [_section, make, model] = section ?? [];
  if (make) {
    const formattedModelValue = model ? ` ${model}` : '';

    return {
      ...faqSchema,
      makeModel: `${make}${formattedModelValue}`,
    };
  }
  return faqSchema;
};

/*
 * We take the section value that contains the path parameters for the Search Results Page ([...section])
 * and return them as individual values
 */
const formatPathParameters = (
  section?: string | Array<string>,
): { [key: string]: string | undefined } => {
  const [
    currentSection,
    makePathParameter,
    modelPathParameter,
    SEOFilterValue,
  ] = Array.isArray(section) ? section : [];

  return {
    currentSection,
    makePathParameter,
    modelPathParameter,
    SEOFilterValue,
  };
};

export {
  getSectionParams,
  generateGetAdsRequestParams,
  getQueryParams,
  getFilterPathParameterData,
  formatFaqSchema,
  formatPathParameters,
};
